import type { HandlerOptions } from '@/interfaces/common.interface';
import {
  ConfigurationTaskFilter,
  ConfigurationTaskStatus,
} from '@/interfaces/configurationTask.interface';
import {
  EcommerceGoogleIntegrationStatus,
  EcommerceIntegrationSyncStatus,
  EcommerceSettingsPlanType,
  EcommerceType,
} from '@/interfaces/ecommerce.interface';
import { queryClient } from '@/plugins/vue-query';
import type { CreateAccountOrderDTO } from '@/services/cxm.service.interface';
import { flokService } from '@/services/flok.service';
import type {
  AuthorizeWithCode,
  AuthorizeWithToken,
  UpdateEcommerceDTO,
} from '@/services/flok.service.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { handleAxiosError } from '@/utils/error.utils';
import { toast } from '@/utils/toast.utils';

export const useCurrentEcommerce = () => {
  const { t } = useTranslations();
  const authStore = useAuthStore();
  const ecommerceStore = useEcommerceStore();
  const ecommerces = computed(() => ecommerceStore.ecommerces);
  const currentEcommerce = computed(() => ecommerceStore.currentEcommerce);
  const configurationTasks = computed(() => ecommerceStore.configuration_tasks);
  const router = useRouter();

  /**
   * Handles creating a new ecommerce and associated order.
   * @param {{ecommerce: Partial<UpdateEcommerceDTO>, order: CreateAccountOrderDTO}} data - The ecommerce and order data.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onCreateEcommerceHandler(
    data: CreateAccountOrderDTO,
    options: HandlerOptions = { silent: false },
  ) {
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.create_store_loading') })
      : null;
    try {
      await authStore.createOrder(data);

      loadingToast?.resolve({ message: t('notifications.create_store_success') });
    } catch (error) {
      const { message, data } = handleAxiosError(error);

      loadingToast?.error({ message: (data as string) ?? message });

      throw new Error((data as string) ?? message);
    }
  }

  /**
   * Handles syncing ecommerce products.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   * @returns {Promise<void>}
   */
  function onSyncEcommerceProductsHandler(
    options: HandlerOptions = { silent: false },
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!currentEcommerce.value?.id) {
        reject();
        return;
      }

      const loadingToast = !options.silent
        ? toast.loading({
            message: t('notifications.sync_products_loading'),
          })
        : null;

      flokService
        .syncEcommerceProducts(currentEcommerce.value?.id)
        .then(() => {
          const interval = setInterval(async () => {
            if (!currentEcommerce.value?.id) {
              clearInterval(interval);
              reject();
              return;
            }

            try {
              const response = await flokService.getEcommerceProviderIntegration(
                currentEcommerce.value?.id,
              );

              if (
                response.data.data.products_sync_status === EcommerceIntegrationSyncStatus.ERROR
              ) {
                clearInterval(interval);
                loadingToast?.reject({ message: t('notifications.sync_products_error') });
                reject();
                return;
              }

              if (
                response.data.data.products_sync_status === EcommerceIntegrationSyncStatus.SYNCED
              ) {
                clearInterval(interval);
                loadingToast?.resolve({ message: t('notifications.sync_products_success') });
                await queryClient.removeQueries({ queryKey: ['ecommerces'] });
                await ecommerceStore.getEcommerces();
                await queryClient.invalidateQueries({ queryKey: ['products'] });
                await queryClient.invalidateQueries({ queryKey: ['ecommerce-product-stats'] });
                resolve();
              }
            } catch (error) {
              const { message } = handleAxiosError(error);
              clearInterval(interval);
              loadingToast?.reject({ message });
              reject();
            }
          }, 3000);
        })
        .catch((error) => {
          const { message } = handleAxiosError(error);
          loadingToast?.reject({ message });
          reject();
        });
    });
  }

  /**
   * Handles syncing ecommerce sales.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   * @returns {Promise<void>}
   */
  function onSyncEcommerceSalesHandler(options: HandlerOptions = { silent: false }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!currentEcommerce.value?.id) {
        reject();
        return;
      }

      const loadingToast = !options.silent
        ? toast.loading({ message: t('notifications.sync_orders_loading') })
        : null;

      flokService
        .syncEcommerceSales(currentEcommerce.value?.id)
        .then(() => {
          const interval = setInterval(async () => {
            if (!currentEcommerce.value?.id) {
              clearInterval(interval);
              reject();
              return;
            }

            try {
              const response = await flokService.getEcommerceProviderIntegration(
                currentEcommerce.value?.id,
              );

              if (response.data.data.orders_sync_status === EcommerceIntegrationSyncStatus.ERROR) {
                clearInterval(interval);
                loadingToast?.reject({ message: t('notifications.sync_orders_error') });
                reject();
                return;
              }

              if (response.data.data.orders_sync_status === EcommerceIntegrationSyncStatus.SYNCED) {
                clearInterval(interval);
                loadingToast?.resolve({ message: t('notifications.sync_orders_success') });
                await queryClient.removeQueries({ queryKey: ['ecommerces'] });
                await ecommerceStore.getEcommerces();
                await queryClient.invalidateQueries({ queryKey: ['orders'] });
                await queryClient.invalidateQueries({ queryKey: ['ecommerce-sales-stats'] });
                resolve();
              }
            } catch (error) {
              const { message } = handleAxiosError(error);
              clearInterval(interval);
              loadingToast?.reject({ message });
              reject();
            }
          }, 3000);
        })
        .catch((error) => {
          const { message } = handleAxiosError(error);
          loadingToast?.reject({ message });
          reject();
        });
    });
  }

  /**
   * Handles setting up the ecommerce tag manager integration.
   * @param {{external_account: boolean}} data - The analytics integration data.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onSetupEcommerceTagManagerIntegrationHandler(
    data: { external_account: boolean },
    options: HandlerOptions = { silent: false },
  ) {
    if (!currentEcommerce.value?.id) return;
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.setup_tagmanager_loading') })
      : null;

    try {
      await flokService.setupEcommerceTagManagerIntegration(currentEcommerce.value?.id, data);

      await queryClient.removeQueries({ queryKey: ['ecommerces'] });
      await ecommerceStore.getEcommerces();

      await onRefreshConfigurationTasksHandler({ silent: true });

      loadingToast?.resolve({ message: t('notifications.setup_tagmanager_success') });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.reject({ message: `${t('notifications.setup_tagmanager_error')}: ${message}` });

      throw error;
    }
  }

  /**
   * Handles updating the wizard configuration task.
   * @param {ConfigurationTaskFilter} type - The type of the configuration task.
   * @param {ConfigurationTaskStatus} status - The new status of the configuration task.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onUpdateWizardConfigurationTaskHandler(
    type: ConfigurationTaskFilter,
    status: ConfigurationTaskStatus,
    options: HandlerOptions = { silent: false },
  ) {
    if (!currentEcommerce.value?.id) return;

    const taskToUpdate = configurationTasks.value.find((task) => task.name.includes(type));

    try {
      if (!taskToUpdate) return console.error({ message: 'Task not found' });
      await ecommerceStore.updateConfigurationTask(taskToUpdate?.id, {
        status: status,
      });
    } catch (error) {
      const { message } = handleAxiosError(error);
      if (!options.silent) {
        toast.error({ message });
      }

      throw error;
    }
  }

  /**
   * Handles setting up the ecommerce merchant integration.
   * @param {{external_account: boolean}} data - The analytics integration data.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onSetupEcommerceMerchantIntegrationHandler(
    data: { external_account: boolean },
    options: HandlerOptions = { silent: false },
  ) {
    if (!currentEcommerce.value?.id) return;
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.setup_merchant_loading') })
      : null;

    try {
      await flokService.setupEcommerceMerchantIntegration(currentEcommerce.value?.id, data);

      await queryClient.removeQueries({ queryKey: ['ecommerces'] });
      await ecommerceStore.getEcommerces();

      await onRefreshConfigurationTasksHandler({ silent: true });

      loadingToast?.resolve({ message: t('notifications.setup_merchant_success') });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.reject({ message: `${t('notifications.setup_merchant_error')}: ${message}` });

      throw error;
    }
  }

  /**
   * Handles setting up the ecommerce analytics integration.
   * @param {{external_account: boolean}} data - The analytics integration data.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onSetupEcommerceAnalyticsIntegrationHandler(
    data: { external_account: boolean },
    options: HandlerOptions = { silent: false },
  ) {
    if (!currentEcommerce.value?.id) return;
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.setup_analytics_loading') })
      : null;

    try {
      await flokService.setupEcommerceAnalyticsIntegration(currentEcommerce.value?.id, data);

      await queryClient.removeQueries({ queryKey: ['ecommerces'] });
      await ecommerceStore.getEcommerces();

      await onRefreshConfigurationTasksHandler({ silent: true });

      loadingToast?.resolve({ message: t('notifications.setup_analytics_success') });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.reject({ message: `${t('notifications.setup_analytics_error')}: ${message}` });

      throw error;
    }
  }

  /**
   * Handles setting the selected ecommerce.
   * @param {number} ecommerce_id - The ID of the ecommerce to select.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onSetSelectedEcommerceHandler(
    ecommerce_id: number,
    options: HandlerOptions = { silent: false },
  ) {
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.change_store_loading') })
      : null;

    try {
      await ecommerceStore.setSelectedEcommerce(ecommerce_id);

      loadingToast?.resolve({
        message: t('notifications.change_store_success', {
          store_name: ecommerceStore.currentEcommerce?.name,
        }),
      });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.reject({ message });
      throw error;
    }
  }

  /**
   * Handles updating the ecommerce.
   * @param {Partial<UpdateEcommerceDTO>} data - The data to update the ecommerce with.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onUpdateEcommerceHandler(
    data: Partial<UpdateEcommerceDTO>,
    options: HandlerOptions = { silent: false },
  ) {
    if (!currentEcommerce.value?.id) throw new Error('Ecommerce is not set.');
    const loadingToast = !options.silent
      ? toast.loading({ message: t('notifications.update_store_loading') })
      : null;

    try {
      await ecommerceStore.updateEcommerce(currentEcommerce.value?.id, data);

      loadingToast?.resolve({
        message: t('notifications.update_store_success', {
          store_name: ecommerceStore.currentEcommerce?.name,
        }),
      });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.reject({ message });
      throw error;
    }
  }

  /**
   * Handles authorizing the ecommerce.
   * @param {AuthorizeWithCode | AuthorizeWithToken} data - The authorization data.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onAuthorizeEcommerceHandler(
    data: AuthorizeWithCode | AuthorizeWithToken,
    options: HandlerOptions = { silent: false },
  ) {
    try {
      if (!currentEcommerce.value?.id) throw new Error('Ecommerce is not set.');
      await ecommerceStore.authorizeEcommerce(currentEcommerce.value?.id, data);
    } catch (error) {
      const { message } = handleAxiosError(error);
      router.push({ name: 'WizardSelectEcommerceProviderView' });
      if (!options.silent) toast.error({ message });
      throw error;
    }
  }

  /**
   * Handles refreshing the configuration tasks.
   * @param {HandlerOptions} [options] - Optional parameters.
   * @param {boolean} [options.silent=false] - Whether to suppress toast notifications.
   */
  async function onRefreshConfigurationTasksHandler(options: HandlerOptions = { silent: false }) {
    try {
      await queryClient.removeQueries({
        queryKey: ['configuration-tasks', ecommerceStore.current_ecommerce_id],
      });
      await ecommerceStore.getConfigurationTasks();
    } catch (error) {
      const { message } = handleAxiosError(error);
      if (!options.silent) toast.error({ message });
      throw error;
    }
  }

  const isGoogleAnalyticsActive = computed(
    () =>
      currentEcommerce.value?.google_analytics_integration?.status ===
      EcommerceGoogleIntegrationStatus.ACTIVE,
  );
  const isGoogleMerchantActive = computed(
    () =>
      currentEcommerce.value?.google_merchant_integration?.status ===
      EcommerceGoogleIntegrationStatus.ACTIVE,
  );
  const isGoogleMerchantPending = computed(
    () =>
      currentEcommerce.value?.google_merchant_integration?.status ===
      EcommerceGoogleIntegrationStatus.PENDING,
  );
  const isGoogleTagManagerActive = computed(() =>
    currentEcommerce.value?.type === EcommerceType.MERCADO_SHOP
      ? true
      : currentEcommerce.value?.google_tag_manager_integration?.status ===
        EcommerceGoogleIntegrationStatus.ACTIVE,
  );
  const isGoogleAdsActive = computed(
    () =>
      currentEcommerce.value?.google_ads_integration?.status ===
      EcommerceGoogleIntegrationStatus.ACTIVE,
  );

  const isGoogleAdsPending = computed(
    () =>
      currentEcommerce.value?.google_ads_integration?.status ===
      EcommerceGoogleIntegrationStatus.PENDING,
  );

  const isGoogleAdsInactive = computed(
    () =>
      currentEcommerce.value?.google_ads_integration?.status ===
      EcommerceGoogleIntegrationStatus.INACTIVE,
  );

  const isIntegrationsActive = computed(
    () =>
      isGoogleAnalyticsActive.value &&
      isGoogleMerchantActive.value &&
      isGoogleTagManagerActive.value &&
      isGoogleAdsActive.value,
  );

  const isIntegrationsSetup = computed(() =>
    !!currentEcommerce.value?.google_ads_integration &&
    currentEcommerce.value?.type === EcommerceType.MERCADO_SHOP
      ? true
      : !!currentEcommerce.value?.google_tag_manager_integration &&
        !!currentEcommerce.value?.google_merchant_integration &&
        !!currentEcommerce.value?.google_analytics_integration,
  );

  const isGoogleAnalyticsWizardDone = computed(
    () =>
      !!currentEcommerce.value?.google_analytics_integration &&
      !!configurationTasks.value?.find(
        (t) =>
          t.name.includes(ConfigurationTaskFilter.Analytics) &&
          t.status === ConfigurationTaskStatus.DONE,
      ),
  );
  const isGoogleMerchantWizardDone = computed(
    () =>
      !!currentEcommerce.value?.google_merchant_integration &&
      !!configurationTasks.value?.find(
        (t) =>
          t.name.includes(ConfigurationTaskFilter.Merchant) &&
          t.status === ConfigurationTaskStatus.DONE,
      ),
  );
  const isGoogleTagManagerWizardDone = computed(() =>
    currentEcommerce.value?.type === EcommerceType.MERCADO_SHOP
      ? true
      : !!currentEcommerce.value?.google_tag_manager_integration &&
        !!configurationTasks.value?.find(
          (t) =>
            t.name.includes(ConfigurationTaskFilter.TagManager) &&
            t.status === ConfigurationTaskStatus.DONE,
        ),
  );

  const isWizardsDone = computed(
    () =>
      isGoogleAnalyticsWizardDone.value &&
      isGoogleMerchantWizardDone.value &&
      isGoogleTagManagerWizardDone.value,
  );

  return {
    //data
    currentEcommerce,
    ecommerces,
    configurationTasks,
    isPaid: computed(
      () => currentEcommerce.value?.settings.flokee_plan_type === EcommerceSettingsPlanType.PAID,
    ),
    hasDiscriminateTrafficInStats: computed(
      () => currentEcommerce.value?.settings.discriminate_traffic_in_stats,
    ),
    isGoogleAnalyticsActive,
    isGoogleMerchantActive,
    isGoogleMerchantPending,
    isGoogleTagManagerActive,
    isGoogleAdsActive,
    isGoogleAdsPending,
    isGoogleAdsInactive,
    isIntegrationsActive,
    isIntegrationsSetup,
    isGoogleAnalyticsWizardDone,
    isGoogleMerchantWizardDone,
    isGoogleTagManagerWizardDone,
    isWizardsDone,
    isLoading: computed(() => ecommerceStore.isLoadingEcommerces),
    logo: computed(
      () =>
        currentEcommerce.value?.logo?.original?.url ??
        '/images/placeholders/store-logo-placeholder.webp',
    ),
    //methods
    onCreateEcommerceHandler,
    onUpdateEcommerceHandler,
    onSyncEcommerceProductsHandler,
    onSyncEcommerceSalesHandler,
    onSetupEcommerceTagManagerIntegrationHandler,
    onSetupEcommerceMerchantIntegrationHandler,
    onSetupEcommerceAnalyticsIntegrationHandler,
    onSetSelectedEcommerceHandler,
    onAuthorizeEcommerceHandler,
    onUpdateWizardConfigurationTaskHandler,
    onRefreshConfigurationTasksHandler,
  };
};

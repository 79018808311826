import type { OrderPlan } from './order.interface';
import type { OrderPlanChange } from './orderPlanChange.interface';
import type { Plan } from './plan.interface';

export interface OrderSummary {
  current_order_plan: OrderPlan;
  default_plan: Plan;
  available_plans: Plan[];
  active_order_plan_change: OrderPlanChange;
  active_order_subscription: OrderSubscription;
}

export enum OrderSubscriptionStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  CLARIFY = 'CLARIFY',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  CANCELED = 'CANCELED',
  PRE_SUSPENDED = 'PRE-SUSPENDED',
}
export interface OrderSubscription {
  id: number;
  start_date: string;
  end_date: string;
  cycles: number;
  order_id: number;
  payment_method_id: number;
  payment_method_name: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  status: OrderSubscriptionStatus;
  recur_enabled: number;
  recur_id: null;
  is_main: number;
}

export enum EcommerceSocketEvents {
  SuggestionProgress = 'suggestion.progress',
}

export interface SuggestionProgressEventPayload {
  ecommerce_id: number;
  product_id: number;
  status: 'active';
  step: 'started' | 'screenshot_taken' | 'html_scrapped' | 'done' | 'error';
  timestamp: string;
}

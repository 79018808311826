import { engeniApi } from '@/api/engeni.api';
import type { Account } from '@/interfaces/account.interface';
import type { AccountBillingInformation } from '@/interfaces/accountBillingInformation.interface';
import type {
  ApiResponse,
  ApiResponseData,
  ApiResponsePaginated,
} from '@/interfaces/api.interface';
import type { Invoice } from '@/interfaces/invoice.interface';
import type { PaymentType } from '@/interfaces/paymentType.interface';
import type { Plan } from '@/interfaces/plan.interface';
import type { Language, User } from '@/interfaces/user.interface';
import type {
  CreateAccountDTO,
  CreateAccountOrderDTO,
  CreateOrderPlanChangeDTO,
  UpdateAccountDTO,
  UpdateUserDTO,
} from './cxm.service.interface';
import type { Order } from '@/interfaces/order.interface';
import type { Cart } from '@/interfaces/cart.interface';
import type { Offer } from '@/interfaces/offer.interface';
import type { NpsPoll } from '@/modules/nps/interfaces/nps.interface';
import type { OrderProductsOverview } from '@/interfaces/orderProductsOverview.interface';
import type { LandingStats } from '@/modules/geo/interfaces/landingStats.interface';
import type { GoogleAdsStats } from '@/modules/geo/interfaces/googleAdsStats.interface';
import type { UserNotification } from '@/interfaces/userNotification.interface';
import type {
  OrderPlanChange,
  OrderPlanChangePlanSummary,
} from '@/interfaces/orderPlanChange.interface';
import type { OrderSummary } from '@/interfaces/orderSummary.interface';
import type { CancellationReason } from '@/interfaces/cancellationReason.interface';

const BASE_PATH = '/cxm';
const BASE_PUBLIC_PATH = `/public${BASE_PATH}`;

export const cxmService = {
  async register(data: {
    name: string;
    email: string;
    password: string;
    timezone: string;
    language_id: number;
  }) {
    return await engeniApi.post<ApiResponseData<User>>(`${BASE_PUBLIC_PATH}/auth/register`, data);
  },
  async login(email: string, password: string) {
    return await engeniApi.post<ApiResponseData<{ access_token: string; user: User }>>(
      `${BASE_PUBLIC_PATH}/auth/login`,
      {
        email: email,
        password: password,
      },
    );
  },
  async tiendanubeLogin(code: string) {
    return await engeniApi.post<ApiResponseData<{ access_token: string; user: User }>>(
      `${BASE_PUBLIC_PATH}/auth/tiendanube/login`,
      {
        code,
      },
    );
  },
  async googleLogin(code: string) {
    return await engeniApi.post<ApiResponseData<{ access_token: string; user: User }>>(
      `${BASE_PUBLIC_PATH}/auth/google/login`,
      {
        code,
      },
    );
  },
  async facebookLogin(code: string) {
    return await engeniApi.post<ApiResponseData<{ access_token: string; user: User }>>(
      `${BASE_PUBLIC_PATH}/auth/facebook/login`,
      {
        code,
      },
    );
  },
  async meliLogin(code: string) {
    return await engeniApi.post<ApiResponseData<{ access_token: string; user: User }>>(
      `${BASE_PUBLIC_PATH}/auth/mercadolibre/login`,
      {
        code,
      },
    );
  },
  async logout() {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/auth/logout`);
  },
  async forgotPassword(email: string) {
    return await engeniApi.post<ApiResponseData<{ message: string }>>(
      `${BASE_PUBLIC_PATH}/auth/forgot?reset_link=${window.location.origin}/auth/reset-password`,
      {
        email: email,
      },
    );
  },
  async resetPassword(
    token: string,
    email: string,
    password: string,
    password_confirmation: string,
  ) {
    return await engeniApi.post<ApiResponseData<{ message: string }>>(
      `${BASE_PUBLIC_PATH}/auth/reset`,
      {
        token: token,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      },
    );
  },
  async getCurrentUser() {
    return await engeniApi.get<ApiResponseData<User>>(
      `${BASE_PATH}/auth/user?embed=accounts,currentAccount.owner,currentAccount.partner,currentAccount.partner.currency,currentAccount.country,currentOrder,role,language`,
    );
  },
  async updateUser(user_id: number, data: Partial<UpdateUserDTO>) {
    return await engeniApi.put<ApiResponse>(`${BASE_PATH}/users/${user_id}`, data);
  },
  async setPassword(user_id: number, data: { password: string; password_confirmation: string }) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/users/${user_id}/set-password`, data);
  },
  async getOrders(filter?: Record<string, any>, pagination?: { page: number; per_page: number }) {
    return await engeniApi.get<ApiResponsePaginated<Order[]>>(`${BASE_PATH}/orders?sort=-id`, {
      params: { ...filter, ...pagination },
    });
  },
  async getOrdersByAccountId(account_id: number) {
    return await engeniApi.get<ApiResponseData<Order[]>>(
      `${BASE_PATH}/orders?account_id=${account_id}&no_pagination=1`,
    );
  },
  async getOrderById(order_id: number) {
    return await engeniApi.get<ApiResponseData<Order>>(`${BASE_PATH}/orders/${order_id}`);
  },
  async getOrderProductsOverview(order_id: number) {
    return await engeniApi.get<ApiResponseData<OrderProductsOverview>>(
      `${BASE_PATH}/orders/${order_id}/products-overview`,
    );
  },
  async createOrder(data: CreateAccountOrderDTO) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/orders`, data);
  },
  async setCurrentOrder(user_id: number, order_id: number) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/users/${user_id}/set-current-order`, {
      order_id,
    });
  },
  async createOrderPlanChange(order_id: number, data: CreateOrderPlanChangeDTO) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/orders/${order_id}/plan-changes`, data);
  },
  async cancelOrderPlanChange(order_id: number, order_plan_change_id: number) {
    return await engeniApi.post<ApiResponse>(
      `${BASE_PATH}/orders/${order_id}/plan-changes/${order_plan_change_id}/cancel`,
    );
  },
  async getOrderPlanChanges(order_id: number) {
    return await engeniApi.get<ApiResponseData<OrderPlanChange[]>>(
      `${BASE_PATH}/orders/${order_id}/plan-changes`,
    );
  },
  async getOpenedOrderPlanChange(order_id: number) {
    return await engeniApi.get<ApiResponseData<OrderPlanChange>>(
      `${BASE_PATH}/orders/${order_id}/plan-changes/opened`,
    );
  },
  async getOrderPlanChangePreview(
    order_id: number,
    data: Pick<CreateOrderPlanChangeDTO, 'new_plan_id' | 'type'>,
  ) {
    return await engeniApi.post<ApiResponseData<OrderPlanChange>>(
      `${BASE_PATH}/orders/${order_id}/plan-changes/preview`,
      data,
    );
  },
  async getOrderSummary(order_id: number) {
    return await engeniApi.get<ApiResponseData<{ summary: OrderSummary }>>(
      `${BASE_PATH}/orders/${order_id}/summary`,
    );
  },
  async getAccounts(filter?: Record<string, any>, pagination?: { page: number; per_page: number }) {
    return await engeniApi.get<ApiResponsePaginated<Account[]>>(
      `${BASE_PATH}/accounts?embed=partner,owner,country&sort=-id`,
      {
        params: { ...filter, ...pagination },
      },
    );
  },
  async createAccount(data: CreateAccountDTO) {
    return await engeniApi.post<ApiResponseData<Account>>(`${BASE_PATH}/accounts/create`, data);
  },
  async setCurrentAccount(user_id: number, account_id: number) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/users/${user_id}/set-current-account`, {
      account_id,
    });
  },
  async updateAccount(account_id: number, data: Partial<UpdateAccountDTO>) {
    return await engeniApi.put<ApiResponseData<Account>>(
      `${BASE_PATH}/accounts/${account_id}`,
      data,
    );
  },
  async getAccountUsers(account_id: number) {
    return await engeniApi.get<ApiResponseData<User[]>>(
      `${BASE_PATH}/accounts/${account_id}/users?no_pagination=1&embed=role`,
    );
  },
  async deleteAccountUser(account_id: number, user_id: number) {
    return await engeniApi.delete<ApiResponse>(
      `${BASE_PATH}/accounts/${account_id}/users/${user_id}`,
    );
  },

  async getAccountBillingInformation(account_id: number) {
    const { data: response } = await engeniApi.get<ApiResponseData<AccountBillingInformation[]>>(
      `${BASE_PATH}/account_billing_information/?account_id=${account_id}&no_pagination=1`,
    );

    return response.data[0];
  },
  async getAccountInvoices(account_id: number) {
    return await engeniApi.get<ApiResponseData<Invoice[]>>(
      `${BASE_PATH}/accounts/${account_id}/invoices`,
    );
  },
  async retryChargeInvoice(account_id: number, invoice_id: number) {
    return await engeniApi.post<ApiResponse>(
      `${BASE_PATH}/accounts/${account_id}/invoices/${invoice_id}/retry-charge`,
    );
  },
  async getAccountPaymentTypes(account_id: number) {
    return await engeniApi.get<ApiResponseData<PaymentType[]>>(
      `${BASE_PATH}/accounts/${account_id}/payment-types?no_pagination=1`,
    );
  },
  async createPaymentType(account_id: number, data: Partial<PaymentType>) {
    return await engeniApi.post<ApiResponseData<PaymentType>>(
      `${BASE_PATH}/accounts/${account_id}/payment-types`,
      data,
    );
  },
  async updatePaymentType(account_id: number, payment_type_id: number, data: Partial<PaymentType>) {
    return await engeniApi.put<ApiResponseData<PaymentType>>(
      `${BASE_PATH}/accounts/${account_id}/payment-types/${payment_type_id}`,
      data,
    );
  },
  async deletePaymentType(account_id: number, payment_type_id: number) {
    return await engeniApi.delete<ApiResponseData<PaymentType>>(
      `${BASE_PATH}/accounts/${account_id}/payment-types/${payment_type_id}`,
    );
  },
  async updateAccountBillingInformation(
    billing_information_id: number,
    data: Partial<AccountBillingInformation>,
  ) {
    return await engeniApi.put<ApiResponseData<PaymentType>>(
      `${BASE_PATH}/account_billing_information/${billing_information_id}`,
      data,
    );
  },
  async getPlanById(plan_id: number) {
    return await engeniApi.get<ApiResponseData<Plan>>(`${BASE_PATH}/plans/${plan_id}`);
  },
  async getOrderPlans(order_id: number) {
    return await engeniApi.get<ApiResponseData<Order>>(`${BASE_PATH}/orders/${order_id}/plans`);
  },
  async contactSupport(data: { subject: string; message: string }) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/contact-supports`, data);
  },
  async getCart(cart_id: number) {
    return await engeniApi.get<ApiResponseData<Cart>>(`${BASE_PATH}/carts/${cart_id}`);
  },
  async createCart(data: { order_id: number; order_plan_id: number }) {
    return await engeniApi.post<ApiResponseData<Cart>>(`${BASE_PATH}/carts`, {
      ...data,
      type: 'UPGRADE',
    });
  },
  async updateCart(cart_id: number, data: { new_plan_id: number; code?: string }) {
    return await engeniApi.put<ApiResponseData<Cart>>(`${BASE_PATH}/carts/${cart_id}`, data);
  },
  async checkoutCart(cart_id: number) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/carts/${cart_id}/checkout`);
  },
  async validateCoupon(data: { code: string; new_plan_id: number }) {
    return await engeniApi.post<ApiResponseData<boolean>>(`${BASE_PATH}/coupons/validate`, data);
  },
  async getLanguages() {
    return await engeniApi.get<ApiResponseData<Language[]>>(
      `${BASE_PUBLIC_PATH}/languages?no_pagination=1`,
    );
  },
  async getOffers(params: { slug?: string; country?: string } = {}) {
    return await engeniApi.get<ApiResponseData<Offer[]>>(
      `${BASE_PATH}/offers?no_pagination=1&embed=solution.partner.country`,
      {
        params,
      },
    );
  },
  async getNpsPoll(nps_id: number) {
    return await engeniApi.get<ApiResponseData<NpsPoll>>(`${BASE_PUBLIC_PATH}/nps/${nps_id}`);
  },
  async registerNpsPoll(nps_id: number, data: { score: number; answer?: string }) {
    return await engeniApi.post<ApiResponseData<boolean>>(
      `${BASE_PUBLIC_PATH}/nps/${nps_id}/register-poll`,
      data,
    );
  },
  async getGoogleAdsStats(order_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<LandingStats<GoogleAdsStats>>>(
      `${BASE_PATH}/googleads/${order_id}/stats`,
      {
        params: { ...filter },
      },
    );
  },
  async getNotifications() {
    return await engeniApi.get<ApiResponseData<UserNotification[]>>(`${BASE_PATH}/notifications`);
  },
  async deleteNotification(notification_id: string) {
    return await engeniApi.delete<ApiResponse>(`${BASE_PATH}/notifications/${notification_id}`);
  },
  async markNotificationAsRead(notification_id: string) {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/notifications/${notification_id}/read`);
  },
  async markAllNotificationsAsRead() {
    return await engeniApi.post<ApiResponse>(`${BASE_PATH}/notifications/read-all`);
  },
  async getCancellationReasons() {
    return await engeniApi.get<ApiResponseData<CancellationReason[]>>(
      `${BASE_PATH}/cancellation-reasons?public=true`,
    );
  },
};

import { engeniApi } from '@/api/engeni.api';
import type {
  ApiResponse,
  ApiResponseData,
  ApiResponsePaginated,
} from '@/interfaces/api.interface';
import {
  EcommerceStatus,
  type Ecommerce,
  type EcommerceGoogleAdsIntegration,
  type EcommerceGoogleAnalyticsIntegration,
  type EcommerceGoogleMerchantIntegration,
  type EcommerceGoogleTagManagerIntegration,
  type EcommerceIntegration,
} from '@/interfaces/ecommerce.interface';
import type { Sale } from '@/interfaces/sale.interface';
import type { Product } from '@/interfaces/product.interface';
import type {
  EcommerceStats,
  ProductsStats,
  SalesStats,
  SummaryStats,
} from '@/interfaces/stats.interface';
import type {
  AuthorizeWithCode,
  AuthorizeWithToken,
  CreatePlanSubscriptionApprovalDTO,
  UpdateEcommerceDTO,
} from './flok.service.interface';
import type { ConfigurationTask } from '@/interfaces/configurationTask.interface';
import type { ProductsMonitor } from '@/interfaces/monitor.interface';
import type { PlanSubscriptionApproval } from '@/interfaces/planSubscriptionApproval.interface';
import type { EcommerceProvider } from '@/interfaces/ecommerceProvider.interface';
import type { ProductSuggestion } from '@/interfaces/productSuggestion.interface';

const BASE_PATH = '/flok';
//const BASE_PUBLIC_PATH = `/public${BASE_PATH}`;

export const flokService = {
  async authorizeEcommerce(ecommerce_id: number, data: AuthorizeWithCode | AuthorizeWithToken) {
    return await engeniApi.post(`${BASE_PATH}/ecommerces/${ecommerce_id}/authorize`, data);
  },
  async getEcommercesByAccountId(account_id: number) {
    return await engeniApi.get<ApiResponseData<Ecommerce[]>>(
      `${BASE_PATH}/ecommerces/?account_id=${account_id}&no_pagination=1&status[]=${EcommerceStatus.ACTIVE}&status[]=${EcommerceStatus.PENDING}&status[]=${EcommerceStatus.SUSPENDED}&status[]=${EcommerceStatus.TEST}`,
    );
  },
  async updateEcommerce(ecommerce_id: number, data: Partial<UpdateEcommerceDTO>) {
    return await engeniApi.put<ApiResponseData<Ecommerce>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}`,
      data,
    );
  },
  async getEcommerceSummaryStats(ecommerce_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<EcommerceStats<SummaryStats>>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/stats/summary`,
      {
        params: { ...filter },
      },
    );
  },
  async getEcommerceProductStats(ecommerce_id: number) {
    return await engeniApi.get<ApiResponseData<EcommerceStats<ProductsStats>>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/stats/products`,
    );
  },
  async getEcommerceSalesStats(ecommerce_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<EcommerceStats<SalesStats>>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/stats/sales`,
      {
        params: { ...filter },
      },
    );
  },
  async getEcommerceProducts(
    ecommerce_id: number,
    filter: Record<string, any>,
    pagination: { page: number; per_page: number },
  ) {
    return await engeniApi.get<ApiResponsePaginated<Product[]>>(
      `${BASE_PATH}/products?ecommerce_id=${ecommerce_id}&sort=availability`,
      {
        params: { ...filter, ...pagination },
      },
    );
  },
  async updateProduct(product_id: number, data: Partial<Product>) {
    return await engeniApi.put<ApiResponseData<Product>>(
      `${BASE_PATH}/products/${product_id}`,
      data,
    );
  },
  async syncEcommerceProducts(ecommerce_id: number) {
    return await engeniApi.post<ApiResponse>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/products/sync`,
    );
  },
  async getEcommerceSales(
    ecommerce_id: number,
    filter: Record<string, any>,
    pagination: { page: number; per_page: number },
  ) {
    return await engeniApi.get<ApiResponsePaginated<Sale[]>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/sales`,
      {
        params: { ...filter, ...pagination },
      },
    );
  },
  async syncEcommerceSales(ecommerce_id: number) {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(toDate.getDate() - 3);

    const from = fromDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const to = toDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    return await engeniApi.post<ApiResponse>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/sales/sync?from=${from}&to=${to}`,
    );
  },
  async getEcommerceProviderIntegration(ecommerce_id: number) {
    return await engeniApi.get<ApiResponseData<EcommerceIntegration>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/integrations/provider`,
    );
  },
  async setupEcommerceTagManagerIntegration(
    ecommerce_id: number,
    data: { external_account: boolean },
  ) {
    return await engeniApi.post<ApiResponseData<EcommerceGoogleTagManagerIntegration>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/integrations/google/tag-manager/setup`,
      data,
    );
  },
  async setupEcommerceMerchantIntegration(
    ecommerce_id: number,
    data: { external_account: boolean },
  ) {
    return await engeniApi.post<ApiResponseData<EcommerceGoogleMerchantIntegration>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/integrations/google/merchant/setup`,
      data,
    );
  },
  async setupEcommerceAnalyticsIntegration(
    ecommerce_id: number,
    data: { external_account: boolean },
  ) {
    return await engeniApi.post<ApiResponseData<EcommerceGoogleAnalyticsIntegration>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/integrations/google/analytics/setup`,
      data,
    );
  },
  async setupEcommerceGoogleAdsIntegration(ecommerce_id: number) {
    return await engeniApi.post<ApiResponseData<EcommerceGoogleAdsIntegration>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/integrations/google/ads/setup`,
    );
  },
  async getConfigurationTasks(ecommerce_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<ConfigurationTask[]>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/configuration-tasks?no_pagination=1`,
      {
        params: { ...filter },
      },
    );
  },
  async updateConfigurationTask(
    ecommerce_id: number,
    configuration_task_id: number,
    data: Partial<ConfigurationTask>,
  ) {
    return await engeniApi.put<ApiResponseData<ConfigurationTask>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/configuration-tasks/${configuration_task_id}`,
      data,
    );
  },
  async getProductsMonitor(ecommerce_id: number) {
    return await engeniApi.get<ApiResponseData<ProductsMonitor>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/stats/monitors?filter_by=products_monitor`,
    );
  },
  async getPlanSubscriptionApprovals(ecommerce_id: number) {
    return await engeniApi.get<ApiResponseData<PlanSubscriptionApproval[]>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/plan_subscription_approvals?no_pagination=1`,
    );
  },
  async createPlanSubscriptionApproval(
    ecommerce_id: number,
    data: CreatePlanSubscriptionApprovalDTO,
  ) {
    return await engeniApi.post<ApiResponseData<PlanSubscriptionApproval>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/plan_subscription_approvals`,
      data,
    );
  },
  async getEcommerceProviders(params: { country?: string } = {}) {
    return await engeniApi.get<ApiResponseData<EcommerceProvider[]>>(
      `${BASE_PATH}/providers?no_pagination=1&enabled=true`,
      {
        params,
      },
    );
  },
  async getProductSuggestions(ecommerce_id: number, product_id: number) {
    return await engeniApi.get<ApiResponseData<ProductSuggestion>>(
      `${BASE_PATH}/ecommerces/${ecommerce_id}/products/${product_id}/suggestions`,
    );
  },
};

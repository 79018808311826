import Pusher from 'pusher-js';

export const pusher = new Pusher(import.meta.env.VITE_APP_PUSHER_APP_KEY, {
  cluster: import.meta.env.VITE_APP_PUSHER_APP_CLUSTER,
  channelAuthorization: {
    endpoint: `${import.meta.env.VITE_APP_ENGENI_API_URL}/cxm/broadcasting/auth`,
    transport: 'ajax',
    headersProvider() {
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };
    },
  },
});

import { defineStore } from 'pinia';
import { useEcommerceStore } from './ecommerce.store';
import { toast } from '@/utils/toast.utils';
import { i18n } from '@/plugins/i18n';
import { queryClient } from '@/plugins/vue-query';

const { t } = i18n.global as any;

interface ProductSuggestionsState {
  drawerOpen: boolean;
  productId?: number;
  currentStep: 'started' | 'screenshot_taken' | 'html_scrapped' | 'done' | 'error';
  loadingProgress: number;
  progressInterval: ReturnType<typeof setInterval> | undefined;
}

export const useProductSuggestionsStore = defineStore('productSuggestions', {
  state: (): ProductSuggestionsState => ({
    drawerOpen: false,
    productId: undefined,
    currentStep: 'started',
    loadingProgress: 0,
    progressInterval: undefined,
  }),
  getters: {},
  actions: {
    closeDrawer() {
      this.drawerOpen = false;
    },
    openDrawer() {
      this.drawerOpen = true;
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    setProductId(productId: number) {
      this.productId = productId;
      const ecommerceStore = useEcommerceStore();

      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }

      this.loadingProgress = 0;

      this.progressInterval = setInterval(() => {
        if (this.loadingProgress >= 99) {
          return clearInterval(this.progressInterval);
        }
        switch (this.currentStep) {
          case 'error':
            break;
          case 'started':
            if (this.loadingProgress < 40) this.loadingProgress++;
            break;
          case 'screenshot_taken':
            if (this.loadingProgress < 75) this.loadingProgress++;
            break;
          case 'html_scrapped':
            if (this.loadingProgress < 100) this.loadingProgress++;
            break;
          default:
            if (this.loadingProgress < 100) this.loadingProgress++;
            break;
        }
      }, 1000);

      if (!ecommerceStore.ecommerceSocket) return;
      ecommerceStore.ecommerceSocket.onSuggestionProgress((data) => {
        this.currentStep = data.step;
        switch (data.step) {
          case 'started':
            if (this.loadingProgress < 20) this.loadingProgress = 20;
            break;
          case 'screenshot_taken':
            if (this.loadingProgress < 40) this.loadingProgress = 40;
            break;
          case 'html_scrapped':
            if (this.loadingProgress < 75) this.loadingProgress = 75;
            break;
          case 'done':
            queryClient.invalidateQueries({
              queryKey: ['product-suggestion', productId],
            });
            this.loadingProgress = 100;
            break;
        }

        if (data.product_id === productId && data.step === 'done' && !this.drawerOpen) {
          toast.success({
            title: t('suggestions.notification.title'),
            message: t('suggestions.notification.description'),
            duration: 10000,
            props: {
              actions: [
                {
                  text: t('suggestions.notification.button'),
                  onClick: () => {
                    this.openDrawer();
                  },
                },
              ],
            },
          });
        }
      });
    },
  },
});

<script setup lang="ts">
import { EcommerceSettingsPlanType } from '@/interfaces/ecommerce.interface';
import SmartIcon from '@/components/common/SmartIcon.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import { PlanSubscriptionApprovalStatus } from '@/interfaces/planSubscriptionApproval.interface';
import { toast } from '@/utils/toast.utils';

const { planSubscriptionModalOpen, closePlanSubscriptionModal } = useUi();
const { currentEcommerce } = useCurrentEcommerce();
const {
  onCreatePlanSubscriptionApprovalHandler,
  isApprovalRequestPending,
  isApprovalRequestRejected,
} = usePlanSubscriptionApproval();
const { t } = useTranslations();

const form = reactive({
  current_plan_type:
    currentEcommerce.value?.settings.flokee_plan_type ?? EcommerceSettingsPlanType.TRIAL,
  new_plan_type: EcommerceSettingsPlanType.PAID,
});

const isLoading = ref(false);
const subscribeModalOpen = ref(false);
const thanksModalOpen = ref(false);
const rejectedModalOpen = ref(false);

async function onSubmit() {
  isLoading.value = true;

  try {
    const { current_plan_type, new_plan_type } = form;
    await onCreatePlanSubscriptionApprovalHandler(
      { status: PlanSubscriptionApprovalStatus.PENDING, current_plan_type, new_plan_type },
      { silent: true },
    );

    subscribeModalOpen.value = false;
    thanksModalOpen.value = true;
  } catch (error) {
    toast.error({ message: t('notifications.create_plan_subscription_approval_error') });
  } finally {
    isLoading.value = false;
  }
}

function closeModal() {
  subscribeModalOpen.value = false;
  thanksModalOpen.value = false;
  rejectedModalOpen.value = false;
  closePlanSubscriptionModal();
}

watch(planSubscriptionModalOpen, (newVal) => {
  if (newVal) {
    if (isApprovalRequestPending.value) {
      thanksModalOpen.value = true;
    } else if (isApprovalRequestRejected.value) {
      rejectedModalOpen.value = true;
    } else {
      subscribeModalOpen.value = true;
    }
  } else {
    closeModal();
  }
});
</script>

<template>
  <ModalDialog
    v-model="subscribeModalOpen"
    @close="
      subscribeModalOpen = false;
      if (!thanksModalOpen) closePlanSubscriptionModal();
    "
    size="2xl"
    contentClass="text-center"
    data-testid="plan-subscription-approval-modal"
  >
    <template #default>
      <h3
        class="mb-3 flex w-full items-center justify-center gap-2 text-center text-xl font-medium"
      >
        <SmartIcon class="w-5" /> {{ t('plan_subscription_approval_drawer.title') }}
      </h3>
      <div class="flex flex-col gap-4">
        <p>
          {{ t('plan_subscription_approval_drawer.description') }}
        </p>
      </div>
    </template>
    <template #footer>
      <PrimaryButton
        @click="onSubmit"
        :isLoading="isLoading"
        class="mx-auto w-full max-w-sm"
        type="submit"
      >
        {{ t('plan_subscription_approval_drawer.button') }}
      </PrimaryButton>
    </template>
  </ModalDialog>

  <ModalDialog v-model="thanksModalOpen" @close="closeModal" size="lg">
    <template #default>
      <h3
        class="mb-3 flex w-full items-center justify-center gap-2 text-center text-xl font-medium"
      >
        {{ t('plan_subscription_approval_drawer.thanks.title') }}
        <SmartIcon class="w-5" />
      </h3>
      <div class="flex flex-col gap-4 text-center">
        <p>
          {{ t('plan_subscription_approval_drawer.thanks.description') }}
        </p>
      </div>
    </template>
    <template #footer>
      <PrimaryButton @click="closeModal" class="mx-auto" type="submit">
        {{ t('common.close') }}
      </PrimaryButton>
    </template>
  </ModalDialog>

  <ModalDialog
    v-model="rejectedModalOpen"
    :title="t('dashboard.cards.approval.rejected_modal.title')"
    contentClass="text-center"
  >
    <template #icon>
      <img class="w-full max-w-32" src="/images/create-account-robot.svg" alt="" />
    </template>
    <p>
      {{ t('dashboard.cards.approval.rejected_modal.description') }}
    </p>
    <p class="mt-2">{{ t('dashboard.cards.approval.rejected_modal.description_2') }}</p>
    <template #footer>
      <div class="flex justify-center gap-4">
        <PrimaryButton @click="closeModal">
          {{ t('common.close') }}
        </PrimaryButton>
      </div>
    </template>
  </ModalDialog>
</template>

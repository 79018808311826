import { ProductSuggestionStatus } from '@/interfaces/productSuggestion.interface';
import { flokService } from '@/services/flok.service';
import { useQuery } from '@tanstack/vue-query';
import type { MaybeRefOrGetter } from 'vue';

export const useProductSuggestions = (product_id?: MaybeRefOrGetter<number | undefined>) => {
  const { currentEcommerce } = useCurrentEcommerce();

  const productId = computed(() => toValue(product_id));

  const suggestionsQueryIsEnabled = computed(() => !!productId.value);

  const { data: productSuggestion, isLoading } = useQuery({
    queryKey: ['product-suggestion', productId],
    queryFn: () => {
      if (!productId.value || !currentEcommerce.value) return;
      return flokService.getProductSuggestions(currentEcommerce.value?.id, productId.value);
    },
    select: (response) => response?.data.data,
    enabled: suggestionsQueryIsEnabled,
    notifyOnChangeProps: 'all',
    gcTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval(query) {
      if (
        query.state.data?.data.data.status !== ProductSuggestionStatus.Done &&
        query.state.data?.data.data.status !== ProductSuggestionStatus.Error
      ) {
        return 7000;
      } else {
        return false;
      }
    },
  });

  const suggestionsByCategory = computed(() => {
    return (
      productSuggestion.value?.suggestions.reduce(
        (acc, suggestion) => {
          if (!acc[suggestion.category]) {
            acc[suggestion.category] = [];
          }
          acc[suggestion.category].push({
            suggestion: suggestion.suggestion,
            title: suggestion.title,
          });
          return acc;
        },
        {} as Record<string, { suggestion: string; title: string }[]>,
      ) ?? {}
    );
  });

  return {
    //data
    suggestions: computed(() => productSuggestion.value?.suggestions ?? []),
    suggestionsByCategory,
    image: computed(() => productSuggestion.value?.image),
    product: computed(() => productSuggestion.value?.product),
    isReady: computed(
      () =>
        productSuggestion.value?.status === ProductSuggestionStatus.Done ||
        productSuggestion.value?.status === ProductSuggestionStatus.Error,
    ),
    isProcessing: computed(
      () => productSuggestion.value?.status === ProductSuggestionStatus.Processing,
    ),
    isDraft: computed(() => productSuggestion.value?.status === ProductSuggestionStatus.Draft),
    isError: computed(() => productSuggestion.value?.status === ProductSuggestionStatus.Error),
    isLoading,
  };
};

import { createI18n, type I18nOptions } from 'vue-i18n';
import en from '@/locales/en.json';
import es from '@/locales/es.json';
import pt from '@/locales/pt.json';
import type { LanguageCode } from '@/interfaces/user.interface';

export type i18nMessageSchema = typeof es;
export const i18n = createI18n<[i18nMessageSchema], 'en' | 'es' | 'pt'>({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'es',
  silentTranslationWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    es: es,
    //@ts-ignore
    en: en,
    //@ts-ignore
    pt: pt,
  },
} satisfies I18nOptions);

export function setLocale(code: LanguageCode) {
  ((i18n.global as any).locale as any).value = code;
}

export async function setLocaleByLanguageCode(code: LanguageCode) {
  setLocale(code as LanguageCode);
}

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';

interface Props {
  percentage: number;
}

const props = withDefaults(defineProps<Props>(), {
  percentage: 0,
});

const animatedPercentage = ref(0);
const circumference = computed(() => 2 * Math.PI * 40);
const dashOffset = computed(() => circumference.value * (1 - animatedPercentage.value / 100));

const animateProgress = () => {
  const duration = 1000; // Animation duration in milliseconds
  const start = animatedPercentage.value;
  const end = props.percentage;
  const startTime = performance.now();

  const animate = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    if (elapsedTime < duration) {
      const progress = elapsedTime / duration;
      animatedPercentage.value = start + (end - start) * progress;
      requestAnimationFrame(animate);
    } else {
      animatedPercentage.value = end;
    }
  };

  requestAnimationFrame(animate);
};

watch(() => props.percentage, animateProgress);

onMounted(animateProgress);
</script>

<template>
  <div class="relative size-[70px] rounded-full p-1 shadow-md">
    <svg class="size-full -rotate-90" viewBox="0 0 100 100">
      <circle
        class="text-gray-200"
        stroke-width="10"
        stroke="currentColor"
        fill="white"
        r="40"
        cx="50"
        cy="50"
      />
      <circle
        class="text-brand-primary transition-all duration-500 ease-out"
        stroke-width="10"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        stroke-linecap="round"
        stroke="currentColor"
        fill="transparent"
        r="40"
        cx="50"
        cy="50"
      />
    </svg>
    <div class="absolute inset-0 flex items-center justify-center">
      <span class="">{{ Math.round(animatedPercentage) }}%</span>
    </div>
  </div>
</template>

import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import type { MaybeRef } from 'vue';
import { OrderStatus } from '@/interfaces/order.interface';
import { notice } from '@/utils/notice.utils';
import { i18n } from '@/plugins/i18n';
import { useAuthStore } from './auth.store';
import { useUiStore } from './ui.store';
import { useOrderSummaryStore } from './orderSummary.store';
import { OrderSubscriptionStatus } from '@/interfaces/orderSummary.interface';
import router from '@/router';

const DEFAULT_NOTICE_EXPIRATION = 1;

export enum NoticeId {
  SuspendedAccount = 'suspended-account',
  PaymentFailed = 'payment-failed',
}

export interface Notice {
  id: NoticeId | string;
  type: 'error' | 'info' | 'warning';
  message: string;
  dismissable: boolean;
  dismissed?: boolean;
  created_at: Date;
  expires_at?: Date | number;
  actions: {
    text: string;
    onClick: () => Promise<void> | void;
  }[];
}

interface NoticeState {
  notices: MaybeRef<Notice[]>;
}

export const useNoticeStore = defineStore('notice', {
  state: (): NoticeState => ({
    notices: useStorage<Notice[]>('notices', [], localStorage, {
      serializer: {
        read: (v: string) => {
          const notices = JSON.parse(v);
          return notices.map((notice: Notice) => ({
            ...notice,
            created_at: new Date(notice.created_at),
            expires_at: notice.expires_at ? new Date(notice.expires_at) : undefined,
          }));
        },
        write: JSON.stringify,
      },
    }),
  }),
  getters: {
    activeNotice: (state) => {
      const now = new Date();
      return state.notices.filter(
        (n) => !n.dismissed && (!n.expires_at || new Date(n.expires_at) > now),
      )[0];
    },
  },
  actions: {
    checkNotices() {
      const { t } = i18n.global as any;
      const authStore = useAuthStore();
      const orderSummaryStore = useOrderSummaryStore();

      //check if account is suspended and show notice
      if (authStore.user?.current_order?.status === OrderStatus.SUSPENDED) {
        notice.error(t('notices.suspended_account.text'), {
          id: NoticeId.SuspendedAccount,
          dismissable: false,
          actions: [
            {
              text: t('common.contact_support'),
              onClick: async () => {
                const uiStore = useUiStore();
                uiStore.openSupportDrawer();
              },
            },
          ],
        });
      } else {
        notice.remove(NoticeId.SuspendedAccount);
      }

      //check if payment failed and show notice
      if (
        orderSummaryStore.orderSummary?.active_order_subscription.status ==
        OrderSubscriptionStatus.FAILED
      ) {
        notice.warning(t('notices.payment_failed.text'), {
          id: NoticeId.PaymentFailed,
          dismissable: false,
          actions: [
            {
              text: t('notices.payment_failed.button'),
              onClick: async () => {
                router.push({ name: 'AccountSettingsBillingView' });
              },
            },
          ],
        });
      } else {
        notice.remove(NoticeId.PaymentFailed);
      }
    },
    addNotice(notice: Omit<Notice, 'created_at'>) {
      //cleans any expired notices before adding a new one
      this.cleanExpiredNotices();

      //check if the notice already exists (not expired since its clean)
      const existingNoticeIdx = this.notices.findIndex((b) => b.id === notice.id);
      //if the notice already exists, update it in case the notice changed, also update the actions since localStorage cant store functions and return
      if (existingNoticeIdx !== -1) {
        return (this.notices[existingNoticeIdx] = {
          ...this.notices[existingNoticeIdx],
          actions: notice.actions,
          message: notice.message,
          type: notice.type,
          dismissable: notice.dismissable,
        });
      }

      //if the notice doesnt exist, add it
      const now = new Date();
      //if the expires_at is a Date object, use it, if its a number, convert it to days ex: 7 = 7days. Default to 7 days.
      const expires_at =
        notice.expires_at instanceof Date
          ? notice.expires_at
          : notice.expires_at
            ? new Date(now.getTime() + notice.expires_at * 24 * 60 * 60 * 1000)
            : new Date(now.getTime() + DEFAULT_NOTICE_EXPIRATION * 24 * 60 * 60 * 1000);

      this.notices.push({
        ...notice,
        created_at: now,
        expires_at,
      });
    },
    removeNotice(notice_id: NoticeId | string) {
      this.notices = this.notices.filter((b) => b.id !== notice_id);
    },
    dismissNotice(notice_id: NoticeId | string) {
      const targetNotice = this.notices.find((b) => b.id === notice_id);
      if (targetNotice) targetNotice.dismissed = true;
    },
    cleanExpiredNotices() {
      const now = new Date();
      this.notices = this.notices.filter(
        (notice) => !notice.expires_at || new Date(notice.expires_at) > now,
      );
    },
  },
});

import { NoticeId, useNoticeStore, type Notice } from '@/stores/notice.store';

/**
 * Utility functions for managing application notices
 */
export const notice = {
  /**
   * Add a new notice to the store
   * @param notice - The notice object to add
   * @param notice.id - Unique identifier for the notice
   * @param notice.type - Type of notice ('error' | 'info' | 'warning')
   * @param notice.message - Message to display
   * @param notice.dismissable - Whether the notice can be dismissed
   * @param notice.actions - Array of actions available for the notice
   * @param notice.expires_at - Optional expiration (Date object or number of days)
   */
  add(notice: Omit<Notice, 'dismissed' | 'created_at'>) {
    const store = useNoticeStore();
    store.addNotice({
      ...notice,
      dismissed: false,
    });
  },

  /**
   * Create and add an error notice
   * @param message - The error message to display
   * @param options - Optional configuration
   * @param {string} [options.id=Date.now()] - Unique identifier
   * @param {boolean} [options.dismissable=true] - Whether notice can be dismissed
   * @param {Array} [options.actions=[]] - Available actions
   * @param {Date|number} [options.expires_at] - When the notice should expire
   * @param {Date|number} [options.expires_at.description] - Pass a Date object for specific datetime, or a number for days from now
   * @example
   * // Expire in 7 days
   * notice.error('Message', { expires_at: 7 })
   * // Expire on specific date
   * notice.error('Message', { expires_at: new Date('2024-12-31') })
   */
  error(message: string, options: Partial<Omit<Notice, 'type' | 'message'>> = {}) {
    this.add({
      id: String(Date.now()),
      type: 'error',
      message,
      dismissable: true,
      actions: [],
      ...options,
    });
  },

  /**
   * Create and add a warning notice
   * @param message - The warning message to display
   * @param options - Optional configuration
   * @param {string} [options.id=Date.now()] - Unique identifier
   * @param {boolean} [options.dismissable=true] - Whether notice can be dismissed
   * @param {Array} [options.actions=[]] - Available actions
   * @param {Date|number} [options.expires_at] - When the notice should expire
   * @param {Date|number} [options.expires_at.description] - Pass a Date object for specific datetime, or a number for days from now
   * @example
   * // Expire in 7 days
   * notice.warning('Message', { expires_at: 7 })
   * // Expire on specific date
   * notice.warning('Message', { expires_at: new Date('2024-12-31') })
   */
  warning(message: string, options: Partial<Omit<Notice, 'type' | 'message'>> = {}) {
    this.add({
      id: String(Date.now()),
      type: 'warning',
      message,
      dismissable: true,
      actions: [],
      ...options,
    });
  },

  /**
   * Create and add an info notice
   * @param message - The info message to display
   * @param options - Optional configuration
   * @param {string} [options.id=Date.now()] - Unique identifier
   * @param {boolean} [options.dismissable=true] - Whether notice can be dismissed
   * @param {Array} [options.actions=[]] - Available actions
   * @param {Date|number} [options.expires_at] - When the notice should expire
   * @param {Date|number} [options.expires_at.description] - Pass a Date object for specific datetime, or a number for days from now
   * @example
   * // Expire in 7 days
   * notice.info('Message', { expires_at: 7 })
   * // Expire on specific date
   * notice.info('Message', { expires_at: new Date('2024-12-31') })
   */
  info(message: string, options: Partial<Omit<Notice, 'type' | 'message'>> = {}) {
    this.add({
      id: String(Date.now()),
      type: 'info',
      message,
      dismissable: true,
      actions: [],
      ...options,
    });
  },

  /**
   * Dismiss a notice by ID
   * @param id - ID of the notice to dismiss
   */
  dismiss(id: NoticeId | string) {
    const store = useNoticeStore();
    store.dismissNotice(id);
  },

  /**
   * Remove a notice by ID
   * @param id - ID of the notice to remove
   */
  remove(id: NoticeId | string) {
    const store = useNoticeStore();
    store.removeNotice(id);
  },
};

import { queryClient } from '@/plugins/vue-query';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth.store';
import type { OrderSummary } from '@/interfaces/orderSummary.interface';
import { cxmService } from '@/services/cxm.service';
import type { CreateOrderPlanChangeDTO } from '@/services/cxm.service.interface';
import * as Sentry from '@sentry/vue';
import { useNoticeStore } from './notice.store';

interface EcommerceState {
  orderSummary?: OrderSummary;
  isLoading: boolean;
}

export const useOrderSummaryStore = defineStore('order-summary', {
  state: (): EcommerceState => ({
    orderSummary: undefined,
    isLoading: false,
  }),
  getters: {},
  actions: {
    async init() {
      await this.getOrderSummary();
    },
    async getOrderSummary() {
      const authStore = useAuthStore();
      const noticesStore = useNoticeStore();

      if (!authStore.user?.current_order_id) return;

      try {
        this.isLoading = true;
        const response = await queryClient.ensureQueryData({
          queryKey: ['order-summary', authStore.user?.current_order_id],
          queryFn: async () => {
            if (!authStore.user?.current_order_id) return;
            const request = await cxmService.getOrderSummary(authStore.user?.current_order_id);
            queryClient.setQueryData(['order-summary', authStore.user?.current_order_id], request);
            return request;
          },
        });

        this.orderSummary = response?.data.data.summary;
        noticesStore.checkNotices();
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createOrderPlanChange(order_id: number, data: CreateOrderPlanChangeDTO) {
      await cxmService.createOrderPlanChange(order_id, data);
      this.refresh();
    },
    async cancelOrderPlanChange(order_id: number, order_plan_change_id: number) {
      await cxmService.cancelOrderPlanChange(order_id, order_plan_change_id);
      this.refresh();
    },
    async refresh() {
      const authStore = useAuthStore();
      await queryClient.removeQueries({
        queryKey: ['order-summary', authStore.user?.current_order_id],
      });
      await this.getOrderSummary();
    },
    async clear() {
      await queryClient.removeQueries({
        queryKey: ['order-summary'],
      });
      this.orderSummary = undefined;
    },
  },
});

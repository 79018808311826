<script setup lang="ts">
export interface AlertAction {
  text: string;
  onClick?: () => Promise<void> | void | 'dismiss';
}

interface Props {
  title?: string;
  message?: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  actions?: AlertAction[];
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  message: undefined,
  type: 'info',
  actions: undefined,
});

const slots = defineSlots<{
  title(): void;
  message(): void;
}>();

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return { name: 'heroicons:check-circle' };
    case 'error':
      return { name: 'heroicons:x-circle' };
    case 'info':
      return { name: 'heroicons:information-circle' };
    case 'warning':
      return { name: 'heroicons:exclamation-triangle' };
    default:
      return { name: 'heroicons:information-circle' };
  }
});
</script>

<template>
  <div
    :class="{
      'border-system-success-green bg-system-success-light': type === 'success',
      'border-system-error-red bg-system-error-light': type === 'error',
      'border-brand-primary bg-brand-primary-soft': type === 'info',
      'border-system-warning-yellow bg-system-warning-light': type === 'warning',
      'border-l-4 !py-6': title,
      '!min-w-[450px]': title,
    }"
    class="flex w-full min-w-[350px] flex-nowrap gap-2 rounded-lg px-3 py-2"
  >
    <div class="flex items-start justify-center" :class="{ 'w-1/12': title }">
      <span
        :class="{
          '!bg-transparent': !title,
          'h-full': !title,
          'bg-system-warning-yellow': type === 'warning',
          'bg-system-success-green': type === 'success',
          'bg-system-error-red': type === 'error',
          'bg-brand-primary': type === 'info',
        }"
        class="flex items-center justify-center rounded-md p-1"
      >
        <IconifyIcon
          :icon="icon.name"
          class="size-6"
          :class="{
            '!text-white': title,
            'text-system-warning-dark': type === 'warning',
            'text-system-error-dark': type === 'error',
            'text-brand-primary-dark': type === 'info',
            'text-system-success-dark': type === 'success',
          }"
        ></IconifyIcon>
      </span>
    </div>
    <div class="w-100 flex flex-col justify-center" :class="{ 'w-11/12': title }">
      <div
        :class="{
          'text-system-warning-dark': type === 'warning',
          'text-system-error-dark': type === 'error',
          'text-brand-primary-dark': type === 'info',
          'text-system-success-dark': type === 'success',
        }"
        v-if="title || !!slots.title"
        class="mb-2 text-lg font-medium leading-4"
      >
        <slot name="title">
          <h3 v-if="title">
            {{ title }}
          </h3>
        </slot>
      </div>
      <div
        :class="{
          'text-system-warning-dark/70': type === 'warning' && title,
          'text-system-error-dark/70': type === 'error' && title,
          'text-brand-primary-dark/70': type === 'info' && title,
          'text-system-success-dark/70': type === 'success' && title,
          'text-system-warning-dark': type === 'warning' && !title,
          'text-system-error-dark': type === 'error' && !title,
          'text-brand-primary-dark': type === 'info' && !title,
          'text-system-success-dark': type === 'success' && !title,
        }"
        class="text-sm"
      >
        <slot name="message">
          <p>
            {{ message }}
          </p>
        </slot>
      </div>
      <div v-if="actions" class="mt-2 flex gap-3">
        <button
          v-for="action in actions"
          :key="action.text"
          type="button"
          class="flex items-center gap-1 text-sm font-medium underline-offset-2 hover:underline"
          :class="{
            'text-system-warning-dark': type === 'warning',
            'text-system-error-dark': type === 'error',
            'text-brand-primary-dark': type === 'info',
            'text-system-success-dark': type === 'success',
          }"
          @click="action.onClick"
        >
          <span>{{ action.text }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
